@use 'sass:math';
@import '@/core/tamagoshi/scss';
@import '@/retention/Homepage/styles/section';

.container {
  background-color: $n0;
}

.title {
  margin-bottom: $spacingMd;

  @include text('h4', 'bold');
  color: $primaryColorDark;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.subtitle {
  margin-top: $spacingXs;
  margin-bottom: 0;

  @include text('body1', 'semiBold');
  color: $primaryColorDark;
}

.text {
  margin-top: 0;
  margin-bottom: $spacingXs;

  @include text('body1');
  color: $primaryColorDark;
}

.section {
  @extend %section;
}

@include size-below('md') {
  .sectionFull {
    padding: $spacingXs $spacingMd $spacingMd;

    .item {
      margin-bottom: $spacingSm;

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    img {
      margin-top: $spacingXs;
      $w: 72px;
      flex: 0 0 $w;
      max-width: $w;
      width: $w;
    }

    .description {
      margin-left: $spacingMd;
    }
  }

  .sectionLight {
    padding: $spacingMd $spacingXs 0;

    .list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: stretch;
    }

    .item {
      margin: 0 $spacingXs $spacingMd;

      $w: calc(50% - #{$spacingMd});
      flex: 0 0 $w;
      max-width: $w;
      width: $w;

      text-align: center;
    }

    img {
      display: block;
      margin: 0 auto;
      width: 32px;
      height: auto;
    }

    .subtitle {
      @include text('small', 'semiBold');
    }
  }
}

@include size-above('md') {
  .title {
    text-align: center;
  }

  .list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;

    > * {
      $w: calc(#{math.div(1, 3) * 100%} - #{math.div((2 * $spacingLg), 3)});
      flex: 0 0 $w;
      max-width: $w;
      width: $w;
    }
  }

  .item {
    text-align: center;
  }

  .sectionFull {
    padding: $spacingXs 0;

    .subtitle {
      margin-top: $spacingMd;
      margin-bottom: $spacingXXs;
    }

    img {
      display: inline;
      width: 88px;
      height: 88px;
    }
  }

  .sectionLight {
    padding: $spacingLg 0;

    .subtitle {
      margin-top: $spacingXs;
      margin-bottom: 0;
    }

    img {
      display: inline;
      width: 48px;
      height: 48px;
    }
  }
}
